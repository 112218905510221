import React, { useState, useEffect } from "react";
import Style from "./Leaderboard.module.scss";
import Assets from "../../Layout/Assests";
import { Col, Row, Card, Placeholder } from "react-bootstrap";
import { useProfile } from "../../../logic/useProfile";
import { useDimensions } from "../../../logic/Dimensions";
import HeaderPwa from "../../Header/HeaderPwa";
import { useLocation, useHistory } from "react-router-dom";

//remove level array and take the unique value from actual data array for headings

function Leaderboard({ className }) {
  const {
    isClubLoading,
    getLeaderBoardData,
    leaderBoard,
    clubType,
    dashboardData,
    getLeaderBoardClubTypeData,
  } = useProfile();
  const location = useLocation();

  useEffect(() => {
    getLeaderBoardData();
    getLeaderBoardClubTypeData();
  }, []);

  // all clubs
  let clubs = (data, id) => {
    if (id == 6) {
      return [data[id - 1], data[id - 2], data[id - 3]];
    } else if (id == 5) {
      return [data[id], data[id - 1], data[id - 2]];
    } else if (id == 4) {
      return [data[id], data[id - 1], data[id - 2]];
    } else if (id == 3) {
      return [data[id], data[id - 1], data[id - 2]];
    } else if (id == 2) {
      return [data[id], data[id - 1], data[id - 2]];
    } else if (id == 1) {
      return [data[id + 1], data[id], data[id - 1]];
    } else {
      return [];
    }
  };

  //medal position 1,2,3
  let clubPosition = (id, type) => {
    if (type.club.id == 6) {
      if (id == 1) {
        return `${Assets.bronze_badge_1}`;
      } else if (id == 2) {
        return `${Assets.bronze_badge_2}`;
      } else if (id == 3) {
        return `${Assets.bronze_badge_3}`;
      }
    } else if (type.club.id == 5) {
      if (id == 1) {
        return `${Assets.badge_one}`;
      } else if (id == 2) {
        return `${Assets.badge_two}`;
      } else if (id == 3) {
        return `${Assets.badge_three}`;
      }
    } else if (type.club.id == 4) {
      if (id == 1) {
        return `${Assets.gold_badge_1}`;
      } else if (id == 2) {
        return `${Assets.gold_badge_2}`;
      } else if (id == 3) {
        return `${Assets.gold_badge_2}`;
      }
    } else if (type.club.id == 3) {
      if (id == 1) {
        return `${Assets.diamond_badge_1}`;
      } else if (id == 2) {
        return `${Assets.diamond_badge_2}`;
      } else if (id == 3) {
        return `${Assets.diamond_badge_3}`;
      }
    } else if (type.club.id == 2) {
      if (id == 1) {
        return `${Assets.platinum_badge_1}`;
      } else if (id == 2) {
        return `${Assets.platinum_badge_2}`;
      } else if (id == 3) {
        return `${Assets.platinum_badge_3}`;
      }
    } else {
      if (id == 1) {
        return `${Assets.emrald_badge_1}`;
      } else if (id == 2) {
        return `${Assets.emrald_badge_2}`;
      } else if (id == 3) {
        return `${Assets.emrald_badge_3}`;
      }
    }
  };

  const { width } = useDimensions();
  return (
    <>
      {width >= 992 ? (
        ""
      ) : (
        <HeaderPwa title="Leaderboard" location={location} />
      )}

      <Card className={`${Style.card} `}>
        <Card.Header className={`${Style.card_header} `}>
          {width >= 992 ? (
            <Card.Title
              className={`${Style.card_title} title-sm ${Style.title_content}`}
            >
              <span>Leaderboard</span>
              {leaderBoard?.leaderboard?.length > 0 ? (
                <span className={Style.days_left}>
                  {leaderBoard?.club?.days_left} days left
                </span>
              ) : (
                ""
              )}
            </Card.Title>
          ) : (
            ""
          )}
          {isClubLoading ? (
            <ul className={Style.medal_wrap}>
              {[1, 2, 3].map((item, index) => {
                return (
                  <>
                    <div className="col-4 px-3 text-center">
                      <div className={`${Style.leader_placeholder} `}>
                        <Placeholder
                          className={`${Style.placeholder} ${Style.placeholder_img} mb-2`}
                          as="p"
                          animation="wave"
                        >
                          <Placeholder
                            xs={12}
                            style={{ width: "55px", height: "55px" }}
                          />
                        </Placeholder>
                        <Placeholder
                          as="p"
                          className={`${Style.placeholder} mb-0 ${Style.placeholder_p}`}
                          animation="wave"
                        >
                          <Placeholder xs={6} className="mx-auto" />
                        </Placeholder>
                      </div>
                    </div>
                  </>
                );
              })}
            </ul>
          ) : leaderBoard?.leaderboard?.length > 0 ? (
            <ul className={Style.medal_wrap}>
              {clubs(clubType, leaderBoard?.club?.id)?.map((item, index) => {
                return (
                  <>
                    <li>
                      <div
                        className={`${Style.medal_single} ${
                          leaderBoard?.club?.id == item?.id
                            ? Style.isActive
                            : ""
                        }`}
                        // onClick={() => handleClickLevel(item, index)}
                      >
                        <img
                          className={Style.medal_single_img}
                          src={item?.club_type_image_full_url}
                        />
                        <p>{`${item?.name}`}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </Card.Header>
        <Card.Body className={Style.card_body}>
          {leaderBoard?.leaderboard?.length == 0 ? (
            leaderBoard?.hasEnteredSecretKey == true ? (
              <p className={`text-center ${Style.leaderboard_info}`}>
                Earn Points to View Leaderboard
              </p>
            ) : (
              <p className={`text-center ${Style.leaderboard_info}`}>
                Enter the <span className="text-success">Secret key</span> to
                enable the Leaderboard
              </p>
            )
          ) : (
            <ul className={Style.medal_item_list}>
              {leaderBoard?.leaderboard?.map((value) => {
                let mySelf =
                  value?.profile_id == leaderBoard?.me?.profile_id
                    ? true
                    : false;

                return (
                  <li
                    className={`${Style.medal_item} ${
                      mySelf && Style.medal_item_current
                    }    
                    ${
                      leaderBoard?.club?.id == 6
                        ? ""
                        : leaderBoard?.club?.id == 5 &&
                          value?.previous_club_position?.rank
                        ? Style.bronze_outline
                        : leaderBoard?.club?.id == 4 &&
                          value?.previous_club_position?.rank
                        ? Style.silver_outline
                        : leaderBoard?.club?.id == 3 &&
                          value?.previous_club_position?.rank
                        ? Style.gold_outline
                        : leaderBoard?.club?.id == 2 &&
                          value?.previous_club_position?.rank
                        ? Style.diamond_outline
                        : leaderBoard?.club?.id == 1 &&
                          value?.previous_club_position?.rank
                        ? Style.platinum_outline
                        : ""
                    }
                    
                    `}
                  >
                    <div className={Style.medal_item_badge}>
                      <span>
                        {value?.position == 1 ||
                        value?.position == 2 ||
                        value?.position == 3 ? (
                          <img
                            src={clubPosition(value?.position, leaderBoard)}
                            alt="club_medals"
                          />
                        ) : (
                          value.position
                        )}
                      </span>
                    </div>
                    <div className={Style.medal_item_label}>
                      {value?.profile_photo_full_url ? (
                        <span className={Style.leaderBoard_avatar}>
                          <img
                            src={value?.profile_photo_full_url}
                            alt={value?.name}
                          />
                        </span>
                      ) : (
                        ""
                      )}

                      {!value?.profile_photo_full_url ? (
                        <span>{value?.name?.toUpperCase().charAt(0)}</span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className={Style.medal_item_name}>
                      <h5 title={value.name}>{value.name.substring(0, 40)}</h5>
                    </div>
                    <div className={Style.medal_item_points}>
                      <p>{`${value.game_points} gal`}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default Leaderboard;
