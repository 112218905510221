import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  activityData: null,
};

export const getActivity = createAsyncThunk(
  "activity/getActivity",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `courses/cues/elements/${params.cueElementOrderId}/details`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logAdditionalRes = createAsyncThunk(
  "activity/logAdditionalRes",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        "learner/audit/additional-resource",
        {
          loggable_id: params.loggable_id,
          status: params.status,
          cue_element_order_id: params.cue_element_order_id,
        },
        {
          headers: { "X-Accept-Learner": params?.leanderId },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitSortActivity = createAsyncThunk(
  "activity/submitSortActivity",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("sorting[0][heading_id]", params.sorting[0].heading_id);
      formData.append("sorting[1][heading_id]", params.sorting[1].heading_id);
      if(params.sorting[0].option_ids.length!=0){
        params.sorting[0].option_ids.map((value, index) => {
          formData.append("sorting[0][option_ids][" + index + "]", value);
        });
      }else{
        formData.append("sorting[0][option_ids][0]", "");
      }
      
      if(params.sorting[1].option_ids.length!=0){
      params.sorting[1].option_ids.map((value, index) => {
        formData.append("sorting[1][option_ids][" + index + "]", value);
      });
    }else{
      formData.append("sorting[1][option_ids][0]", "");
    }
      formData.append("cue_element_order_id", params.cue_element_order_id);
      const response = await api.post(
        `courses/cues/activity/${params.activityId}/${params.pool_activity_type_id}`,
        formData,
        { headers: { "X-Accept-Learner": params?.leanderId } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitMatchActivity = createAsyncThunk(
  "activity/submitMatchActivity",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      params.data.group1.map((value, index) => {
        formData.append("mtf_group_1_ids[" + index + "]", value.id);
      });
      params.data.group2.map((value, index) => {
        formData.append("mtf_group_2_ids[" + index + "]", value.id);
      });
      formData.append("cue_element_order_id", params.cue_element_order_id);
      const response = await api.post(
        `courses/cues/activity/${params.activityId}/${params.pool_activity_type_id}`,
        formData,
        { headers: { "X-Accept-Learner": params?.leanderId } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitMcqActivity = createAsyncThunk(
  "activity/submitMcqActivity",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("mcq_right_option_id", params.right_option);
      formData.append("cue_element_order_id", params.cue_element_order_id);
      formData.append("pool_activity_order_id", params.pool_activity_order_id);

      const response = await api.post(
        `courses/cues/activity/${params.activityId}/${params.pool_activity_type_id}`,
        formData,
        { headers: { "X-Accept-Learner": params?.leanderId } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    clearActivity: (state, action) => {
      state.activityData = null;
    },
  },
  extraReducers: {
    [getActivity.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getActivity.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.activityData = action.payload?.data?.data;
    },
    [getActivity.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.courseData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export const { clearActivity } = activitySlice.actions;

export default activitySlice.reducer;
