import IR_logo from "../../assets/signup/IR_logo.svg";
import IR_bg from "../../assets/signup/IR_bg.svg";
import robo from "../../assets/signup/robo.svg";
import smly from "../../assets/signup/smly.svg";
import brick_p from "../../assets/signup/brick_p.svg";
import sound from "../../assets/signup/sound.svg";
import robo_blue from "../../assets/signup/robo_blue.svg";
import ones from "../../assets/signup/ones.svg";
import twos from "../../assets/signup/twos.svg";
import bubble_light from "../../assets/signup/shape_bubble_light.svg";
import bubble_orange from "../../assets/signup/shape_bubble_orange.svg";
import robo_signup from "../../assets/signup/robo_signup.png";
import insta from "../../assets/footer/insta.svg";
import twitter from "../../assets/footer/twitter.svg";
import fb from "../../assets/footer/fb.svg";
import otp from "../../assets/verify/otp.svg";
import headone from "../../assets/header/logo-icon.svg";
import close from "../../assets/cue/close.svg";
import cue_video from "../../assets/cue/cue_video.png";
import cue1 from "../../assets/cue/cue1.png";
import blue_round from "../../assets/cue/blue_round.svg";
import mark from "../../assets/cue/mark.svg";
// import mark from "../../assets/cue/icon-tick.svg";
import green_mark from "../../assets/cue/green_mark.svg";
import troll from "../../assets/match/troll.png";
import stap from "../../assets/match/stap.png";
import scrr from "../../assets/match/scrr.png";
import wood from "../../assets/match/wood.png";
import red_close from "../../assets/match/red_close.svg";
import green_close from "../../assets/match/green_mark.svg";
import green_tick from "../../assets/match/green_tick.svg";
import pin from "../../assets/mcq/pin.png";
import led from "../../assets/mcq/led.png";
import lock from "../../assets/mcq/lock.png";
import motor from "../../assets/mcq/motor.png";
import battery from "../../assets/mcq/battery.png";
import plug from "../../assets/mcq/plug.png";
import screw from "../../assets/mcq/screw.png";
import axe from "../../assets/mcq/axe.png";
import hook from "../../assets/mcq/hook.png";
import scrss from "../../assets/mcq/scrss.png";
import square from "../../assets/mcq/square.png";
import marks from "../../assets/mcq/marks.png";
import marks_green from "../../assets/mcq/marks_green.png";
import red_close_btn from "../../assets/mcq/red_close_btn.svg";
import green_mark_btn from "../../assets/mcq/green_mark_btn.svg";
import orange_mark_btn from "../../assets/mcq/orange_mark_btn.svg";
import sort_screw from "../../assets/sorting/sort_screw.png";
import sort_scrss from "../../assets/sorting/sort_scrss.png";
import mech_box from "../../assets/sorting/mech_box.svg";
import elec_box from "../../assets/sorting/elec_box.svg";
import grey from "../../assets/sorting/grey.png";

import resistor_bg from "../../assets/flashcard/resistor_bg.svg";
import brown from "../../assets/flashcard/brown.svg";
import mask from "../../assets/flashcard/mask.svg";
import sound_mute from "../../assets/flashcard/sound_mute.svg";

import card_box from "../../assets/sorting/card_box.svg";
// import grey from "../../assets/sorting/grey.png";

import map_bg from "../../assets//map/map_bg.svg";
import bot from "../../assets//map/bot.svg";
import logo_inker from "../../assets/header/MapBasicHeader/logo_inker.svg";
import bell_gal from "../../assets/header/MapBasicHeader/bell_gal.svg";
import crown from "../../assets/header/MapBasicHeader/crown.svg";
import fire from "../../assets/header/MapBasicHeader/fire.svg";
import play_button from "../../assets/map/play_button.svg";
import tube_gal from "../../assets/header/MapBasicHeader/tube_gal.svg";
import violet_color from "../../assets/header/MapBasicHeader/violet_color.svg";

import blue_sound from "../../assets/flashcard/blue_sound.svg";
import orange_refresh from "../../assets/flashcard/orange_refresh.svg";
import grey_refresh from "../../assets/flashcard/grey_refresh.svg";

import resistor from "../../assets/flashcard/resistor.png";
import resistor_flipped from "../../assets/flashcard/resistor_flipped.svg";
import did_bg from "../../assets/flashcard/did_bg.png";
import did_flipped from "../../assets/flashcard/did_flipped.svg";
import did_robo from "../../assets/flashcard/did_robo.svg";
import did_shadow from "../../assets/flashcard/did_shadow.svg";
import spanner_bg from "../../assets/flashcard/spanner_bg.svg";
import spanner from "../../assets/flashcard/spanner.svg";
import spanner_flipped from "../../assets/flashcard/spanner_flipped.svg";
import check_robo from "../../assets/checkout/check_robo.png";
import check_green_mark from "../../assets/checkout/check_green_mark.svg";
import check_right_arrow from "../../assets/checkout/check_right_arrow.svg";
import check_down_arrow from "../../assets/checkout/check_down_arrow.svg";
import safe_sign from "../../assets/checkout/safe_sign.svg";
import success_mark from "../../assets/checkout/success_mark.svg";
import fail_mark from "../../assets/checkout/fail_mark.svg";
import icon_download from "../../assets/checkout/Icon_download.svg";
import a_icon from "../../assets/header/MapBasicHeader/A_icon.svg";
import res_icon from "../../assets/header/MapBasicHeader/res_icon.svg";
import rank1 from "../../assets/dashboard/rank1.svg";
import concept_nil from "../../assets/dashboard/concept_nil.svg";
import badge_outline from "../../assets/dashboard/badge_outline.svg";
import shop_bag from "../../assets/dashboard/shop_bag.svg";
import drop from "../../assets/dashboard/drop.svg";
import rank2 from "../../assets/dashboard/rank2.svg";
import rank3 from "../../assets/dashboard/rank3.svg";
import avatar_bg from "../../assets/dashboard/avatar_bg.svg";
import user_icon from "../../assets/dashboard/user_icon.svg";
import edit from "../../assets/dashboard/edit.png";
import medal from "../../assets/dashboard/medal.png";
import medal1 from "../../assets/dashboard/medal.svg";
import dash_arrow from "../../assets/dashboard/dash_arrow.png";
import dash_arrow2 from "../../assets/dashboard/dash_arrow2.png";
import dashFire from "../../assets/dashboard/fire.svg";
import icon_map from "../../assets/dashboard/map.svg";

import freeze from "../../assets/dashboard/freeze.png";
import robo_fuel_full from "../../assets/reactor/robo_fuel_full.svg";
import mini_reactor from "../../assets/reactor/mini_reactor.png";
import freeze_double from "../../assets/reactor/freeze_double.png";


import streak_freeze from "../../assets/dashboard/streak_freeze.svg";
import badge_dash from "../../assets/dashboard/badge_dash.svg";
import dashGal from "../../assets/dashboard/dashGal.png";
import dashGalRec from "../../assets/dashboard/dashGalRec.png";
import dashShopbag from "../../assets/dashboard/dashShopBag.png";
import bronzeClub from "../../assets/dashboard/bronzeClub.png";
import silverClub from "../../assets/dashboard/silverClub.png";
import goldenClub from "../../assets/dashboard/goldenClub.png";
import cuesComplete from "../../assets/dashboard/cuesComplete.png";
import conceptLearned from "../../assets/dashboard/conceptLearned.svg";
import flashCardCollected from "../../assets/dashboard/flashCardCollected.png";
import timeSpend from "../../assets/dashboard/timeSpend.png";
import video_button from "../../assets/dashboard/video_button.svg";
import certificate_button from "../../assets/dashboard/certificate_button.svg";
import preference_button from "../../assets/dashboard/preference_button.svg";
import user_profile from "../../assets/dashboard/user_profile.svg";
import group_icon from "../../assets/dashboard/group_icon.svg";
import card_icon from "../../assets/dashboard/card_icon.svg";
import preference_icon from "../../assets/dashboard/preferenceIcon.png";

import back_arrow from "../../assets/video_playlist/back_arrow.png";
import icon_search from "../../assets/video_playlist/icon_search.svg";
import order_icon from "../../assets/video_playlist/order_icon.svg";
import video1 from "../../assets/video_playlist/video1.png";
import video2 from "../../assets/video_playlist/video2.png";
import video3 from "../../assets/video_playlist/video3.png";
import video4 from "../../assets/video_playlist/video4.png";
import video5 from "../../assets/video_playlist/video5.png";
import video6 from "../../assets/video_playlist/video6.png";

// quest item
import quest_video from "../../assets/quest/icon-quest-video.svg";
import quest_note from "../../assets/quest/icon-quest-note.svg";
import quest_flash from "../../assets/quest/icon-quest-flash.svg";
import quest_activity from "../../assets/quest/icon-quest-activiity.svg";

// medals item
import medal_bronze from "../../assets/club/bronze-medal.svg";
import medal_silver from "../../assets/club/silver-medal.svg";
import medal_gold from "../../assets/club/gold-medal.svg";
import diamond_club from "../../assets/dashboard/diamond_club.svg";
import platinum_club from "../../assets/dashboard/platinum_club.svg";
import emerald_club from "../../assets/dashboard/emerald_club.svg";
import badge_one from "../../assets/club/badge-one.svg";
import badge_two from "../../assets/club/badge-two.svg";
import badge_three from "../../assets/club/badge-three.svg";
import streak_fire from "../../assets/club/streak-fire.svg";
import snow_drop from "../../assets/club/snow-drop.svg";

//reactor
import reactor from "../../assets/reactor/reactor.svg";
import reactor_overlay from "../../assets/reactor/reactor-overlay.svg";
import lock_3d from "../../assets/3d/lock-3d.svg";
import icon_congrats from "../../assets/3d/icon_congrats.png";
import icon_purchase_warning from "../../assets/3d/icon_purchase_warning.svg";

//modal icons
import icon_exit from "../../assets/3d/icon_exit.png";
//videos
import robo_entry from "../../assets/videos/entry.mp4";
import robo_dis from "../../assets/videos/Disappointed.mp4";
import vid_preference from "../../assets/videos/preference.mp4";
import vid_complete from "../../assets/videos/signupcomplete.mp4";
//profile
import person2 from "../../assets/profile/person2.svg";
import person1 from "../../assets/profile/person1.png";
import indian_flag from "../../assets/profile/indian_flag.png";
import add_button from "../../assets/profile/add_button.svg";
import edit_icon from "../../assets/profile/edit_icon.svg";
import gal_claim from "../../assets/profile/gal_claim.svg";
import social_add_button from "../../assets/profile/social_add_button.png";
import fb_icon from "../../assets/profile/fb_icon.png";
import insta_icon from "../../assets/profile/insta_icon.png";
import tweet_icon from "../../assets/profile/tweet_icon.png";

// fuel
import battery_full from "../../assets/fuel/battery_full.svg";
import battery_half from "../../assets/fuel/battery_half.svg";
import battery_low from "../../assets/fuel/battery_low.svg";

//404
import notFound from "../../assets/404/404.svg";
import city from "../../assets/404/city.svg";

//map
import cloud1 from "../../assets/map/clouds/01.svg";
import cloud2 from "../../assets/map/clouds/02.svg";
import cloud3 from "../../assets/map/clouds/03.svg";
import cloud4 from "../../assets/map/clouds/04.svg";
import cloud5 from "../../assets/map/clouds/05.svg";
import cloud6 from "../../assets/map/clouds/06.svg";
import cloud7 from "../../assets/map/clouds/07.svg";

import robo_map from "../../assets/map/robo.svg";
import started from "../../assets/map/started.svg";
import started_mob from "../../assets/map/started_mob.svg";
import normal_base from "../../assets/map/normal-base.svg";
import checkpoint_base from "../../assets/map/checkPoint.svg";
import checkpoint from "../../assets/map/base-icons/checkpoint.svg";
import step_one from "../../assets/map/base-icons/01.svg";
import step_two from "../../assets/map/base-icons/02.svg";
import step_three from "../../assets/map/base-icons/03.svg";
import step_four from "../../assets/map/base-icons/04.svg";
import step_five from "../../assets/map/base-icons/05.svg";
import step_six from "../../assets/map/base-icons/06.svg";
import step_seven from "../../assets/map/base-icons/07.svg";
import step_eight from "../../assets/map/base-icons/08.svg";
import step_nine from "../../assets/map/base-icons/09.svg";
import blue_ring from "../../assets/map/blue-ring.svg";
import orange_ring from "../../assets/map/orange-ring.svg";
import golden_ring from "../../assets/map/golden-ring.svg";
import key_top from "../../assets/map/map-icons/key-top.svg";
import key_grad from "../../assets/map/map-icons/key_grad.png";

import b1 from "../../assets/badges/learner/b1.svg";
import b2 from "../../assets/badges/socializer/b1.svg";
import b3 from "../../assets/badges/socializer/b2.svg";
import b4 from "../../assets/badges/socializer/b3.svg";
import b5 from "../../assets/badges/socializer/b4.svg";
import b6 from "../../assets/badges/ultimate/b1.svg";
import sm from "../../assets/badges/default/sm.svg";
import lg from "../../assets/badges/default/lg.svg";

import sprinkle_anim from "../../assets/gif/sprinkle.gif";
import sprinkle_anim_grey from "../../assets/gif/sprinkle-grey.gif";
import ripple_anim from "../../assets/gif/ripple.gif";
import ripple_anim_grey from "../../assets/gif/ripple-grey.gif";

//leaderboard

import bronze_badge_1 from "../../assets/leaderboard/bronze1.svg";
import bronze_badge_2 from "../../assets/leaderboard/bronze2.svg";
import bronze_badge_3 from "../../assets/leaderboard/bronze3.svg";

import gold_badge_1 from "../../assets/leaderboard/gold1.svg";
import gold_badge_2 from "../../assets/leaderboard/gold2.svg";
import gold_badge_3 from "../../assets/leaderboard/gold3.svg";

import diamond_badge_1 from "../../assets/leaderboard/diamond1.svg";
import diamond_badge_2 from "../../assets/leaderboard/diamond2.svg";
import diamond_badge_3 from "../../assets/leaderboard/diamond3.svg";

import platinum_badge_1 from "../../assets/leaderboard/platinum1.svg";
import platinum_badge_2 from "../../assets/leaderboard/platinum2.svg";
import platinum_badge_3 from "../../assets/leaderboard/platinum3.svg";

import emrald_badge_1 from "../../assets/leaderboard/emrald1.svg";
import emrald_badge_2 from "../../assets/leaderboard/emrald2.svg";
import emrald_badge_3 from "../../assets/leaderboard/emrald3.svg";

import silver_ring from "../../assets/leaderboard/silverring.svg";
import gold_ring from "../../assets/leaderboard/goldring.svg";
import diamond_ring from "../../assets/leaderboard/diamondring.svg";
import platinum_ring from "../../assets/leaderboard/platinumring.svg";
import spinner from "../../assets/spinner.svg";
import tick_gif from "../../assets/tick_gif.gif";
import inker_loader from "../../assets/inker_loader.gif";

// pop ups
import activity_info from "../../assets/pop_ups/activityInfo.svg";
import daily_dedection from "../../assets/pop_ups/dailyDedection.svg";
import fuel_gain from "../../assets/pop_ups/fuelGain.svg";
import fuel_gain_add from "../../assets/pop_ups/fuelGainAdd.svg";
import fuel_gain_nochange from "../../assets/pop_ups/fuelGainNoChange.svg";
import fuel_loss from "../../assets/pop_ups/fuelloss.svg";
import fuel_loss_sub from "../../assets/pop_ups/fuellossiconbig.svg";

import inform_revisit from "../../assets/pop_ups/informRevisit.svg";
import initial_signup from "../../assets/pop_ups/initialSignup.svg";
import leaderboard_club_reset from "../../assets/pop_ups/leaderBoardClubReset.svg";
import leaderboard_reset from "../../assets/pop_ups/leaderBoardReset.svg";
import purchase_bonous from "../../assets/pop_ups/purchaseBonus.svg";
import revisit from "../../assets/pop_ups/revisit.svg";
import fuel_shape from "../../assets/pop_ups/shapefuel.svg";

// import fuel_loss_banner from "../../assets/pop_ups/.svg";

// import revisit from "../../assets/pop_ups/revisit.svg";

// import revisit from "../../assets/pop_ups/revisit.svg";

// import revisit from "../../assets/pop_ups/revisit.svg";

const Assets = {
  fb_icon,
  insta_icon,
  tweet_icon,
  social_add_button,
  gal_claim,
  edit_icon,
  person1,
  person2,
  indian_flag,
  add_button,
  video1,
  video2,
  video3,
  video4,
  video5,
  video6,
  order_icon,
  icon_search,
  back_arrow,
  user_profile,
  preference_icon,
  group_icon,
  card_icon,
  video_button,
  certificate_button,
  preference_button,
  cuesComplete,
  conceptLearned,
  flashCardCollected,
  timeSpend,
  bronzeClub,
  silverClub,
  goldenClub,
  freeze,
  dashGal,
  dashGalRec,
  dashShopbag,
  dashFire,
  dash_arrow,
  dash_arrow2,
  medal,
  medal1,
  edit,
  user_icon,
  rank1,
  rank2,
  rank3,
  res_icon,
  a_icon,
  icon_download,
  fail_mark,
  safe_sign,
  success_mark,
  check_down_arrow,
  check_right_arrow,
  check_green_mark,
  check_robo,
  spanner,
  spanner_flipped,
  spanner_bg,
  did_bg,
  did_flipped,
  did_robo,
  did_shadow,
  resistor_flipped,
  resistor_bg,
  blue_sound,
  orange_refresh,
  grey_refresh,
  brown,
  sound_mute,
  resistor,
  logo_inker,
  map_bg,
  bell_gal,
  crown,
  fire,
  play_button,
  tube_gal,
  violet_color,
  green_tick,
  green_close,
  grey,
  sort_scrss,
  mech_box,
  elec_box,
  card_box,
  sort_screw,
  cue1,
  orange_mark_btn,
  green_mark_btn,
  red_close_btn,
  marks_green,
  marks,
  screw,
  square,
  axe,
  hook,
  scrss,
  led,
  lock,
  motor,
  battery,
  plug,
  pin,
  red_close,
  troll,
  stap,
  scrr,
  wood,
  green_mark,
  close,
  cue_video,
  blue_round,
  mark,
  headone,
  sound,
  robo_blue,
  ones,
  twos,
  otp,
  insta,
  twitter,
  fb,
  IR_logo,
  IR_bg,
  robo,
  smly,
  quest_video,
  quest_note,
  quest_flash,
  quest_activity,
  medal_bronze,
  medal_silver,
  medal_gold,
  badge_one,
  badge_two,
  badge_three,
  streak_fire,
  snow_drop,
  reactor,
  reactor_overlay,
  lock_3d,
  icon_congrats,
  icon_exit,
  icon_purchase_warning,
  robo_entry,
  robo_dis,
  bubble_light,
  bubble_orange,
  brick_p,
  avatar_bg,
  icon_map,
  robo_signup,

  battery_full,
  battery_half,
  battery_low,
  notFound,
  city,

  cloud1,
  cloud2,
  cloud3,
  cloud4,
  cloud5,
  cloud6,
  cloud7,
  robo_map,
  started,
  started_mob,
  normal_base,
  checkpoint_base,
  checkpoint,
  step_one,
  step_two,
  step_three,
  step_four,
  step_five,
  step_six,
  step_seven,
  step_eight,
  blue_ring,
  orange_ring,
  golden_ring,
  bot,
  key_top,
  key_grad,

  streak_freeze,
  badge_dash,
  shop_bag,
  drop,
  b1,
  b2,
  b3,
  b4,
  b5,
  b6,
  sm,
  lg,
  badge_outline,
  diamond_club,
  platinum_club,
  emerald_club,
  concept_nil,
  vid_preference,
  vid_complete,
  sprinkle_anim,
  sprinkle_anim_grey,
  ripple_anim,
  ripple_anim_grey,
  mask,

  bronze_badge_1,
  bronze_badge_2,
  bronze_badge_3,
  gold_badge_1,
  gold_badge_2,
  gold_badge_3,
  diamond_badge_1,
  diamond_badge_2,
  diamond_badge_3,
  platinum_badge_1,
  platinum_badge_2,
  platinum_badge_3,
  emrald_badge_1,
  emrald_badge_2,
  emrald_badge_3,
  silver_ring,
  gold_ring,
  diamond_ring,
  platinum_ring,
  spinner,

  activity_info,
  daily_dedection,
  fuel_gain,
  fuel_shape,
  fuel_gain_add,
  fuel_loss,
  inform_revisit,
  initial_signup,
  leaderboard_club_reset,
  leaderboard_reset,
  purchase_bonous,
  revisit,
  fuel_loss_sub,
  fuel_gain_nochange,
  robo_fuel_full,
  mini_reactor,
  freeze_double,
  tick_gif,
  inker_loader
};
export default Assets;
