import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  courseStatus: "idle",
  error: null,
  message: null,
  courseData: null,
};

export const getCourse = createAsyncThunk(
  "course/getCourse",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("courses", {
        headers: { "X-Accept-Profile": params?.profileId },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: {
    [getCourse.pending]: (state, action) => {
      state.courseStatus = "pending";
      state.message = null;
    },
    [getCourse.fulfilled]: (state, action) => {
      state.courseStatus = "fulfilled";
      state.courseData = action.payload?.data?.data;
    },
    [getCourse.rejected]: (state, action) => {
      state.courseStatus = "rejected";

      if (action.payload?.data?.length !== 0) {
        state.courseData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default courseSlice.reducer;
