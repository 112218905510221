import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  allProfiles: [],
  userProfile: null,
  notificationsRead: false,
  storeItems: {},
  accordionIndex: "0",
};

export const userPreference = createAsyncThunk(
  "profile/userPreference",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("language_id", params.language_id);
      formData.append("pace_id", params.pace_id);
      formData.append("click_sound", params.click_sound);
      formData.append("backed_sound", params.backed_sound);
      formData.append("notifications_sound", params.notifications_sound);

      const response = await api.post(
        `user/profiles/${params.id}/update-preference`,
        formData
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const markAsReadNotifications = createAsyncThunk(
  "profile/markAsReadNotifications",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/notifications/read`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const markAsReadNotificationById = createAsyncThunk(
  "profile/markAsReadNotificationById",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/notifications/${params.notification_id}/read`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllProfiles = createAsyncThunk(
  "profile/getAllProfiles",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("user/profiles");

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfileOfUser = createAsyncThunk(
  "profile/getProfileOfUser",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user/profiles/${params.profileId}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserProfile = createAsyncThunk(
  "profile/createUserProfile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`user/profiles/create`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfilePhoto = createAsyncThunk(
  "profile/updateProfilePhoto",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("profile_photo", params.img);

      const response = await api.post(
        `user/profiles/${params.profile_Id}/photo-update`,
        formData,
        {
          headers: { "X-HTTP-Method-Override": "PATCH" },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "profile/updateUserProfile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("name", params.name);
      formData.append("mobile_no", params.mobile_no);
      formData.append("phone_country_code", params.phone_country_code);
      formData.append("email", params.email);
      formData.append("alt_email", params.alt_email);
      formData.append("gender_id", params.gender_id);
      formData.append("dob", params.dob);
      formData.append("about_me", params.about_me);
      params.technologies.map((value, index) => {
        formData.append(
          "technology_ids[" + index + "]",
          params.technologies[index]
        );
      });
      params.interests.map((value, index) => {
        formData.append("interest_ids[" + index + "]", params.interests[index]);
      });

      formData.append("billing_address[name]", params.billing_address.name);
      formData.append(
        "billing_address[house_no]",
        params.billing_address.house_no ? params.billing_address.house_no : ""
      );
      formData.append(
        "billing_address[house_name]",
        params.billing_address.house_name
      );
      formData.append(
        "billing_address[street_name]",
        params.billing_address.street_name
      );
      formData.append(
        "billing_address[landmark]",
        params.billing_address.landmark
      );
      formData.append(
        "billing_address[post_office]",
        params.billing_address.post_office
      );
      formData.append("billing_address[zip]", params.billing_address.zip);

      formData.append("billing_address[city]", params.billing_address.city);
      formData.append(
        "billing_address[district_id]",
        params.billing_address.district_id
      );

      formData.append(
        "billing_address[state_id]",
        params.billing_address.state_id
      );
      formData.append(
        "billing_address[country_id]",
        params.billing_address.country_id
      );

      const response = await api.post(`user/profiles/${params.id}`, formData, {
        headers: { "X-HTTP-Method-Override": "PATCH" },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update education

export const updateUserEducation = createAsyncThunk(
  "profile/updateUserEducation",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      var formData = new FormData();
      formData.append("school_name", params.school_name);
      formData.append("board_id", params.board_id);

      const response = await api.post(
        `user/profiles/${params.profile_Id}/education`,
        formData,
        {
          headers: { "X-HTTP-Method-Override": "PATCH" },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update social
export const updateUserSocialMedia = createAsyncThunk(
  "profile/updateUserSocialMedia",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      params.social.map((value, index) => {
        formData.append(
          "accounts[" + index + "][type_id]",
          params.social[index].social_media_type_id
        );
        formData.append(
          "accounts[" + index + "][url]",
          params.social[index].link
        );
        formData.append("accounts[" + index + "][type_status]", "0");
      });
      const response = await api.post(
        `user/profiles/${params.profile_Id.id}/social-media`,
        formData,
        {
          headers: { "X-HTTP-Method-Override": "PATCH" },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get dashBoard
export const getDashBoard = createAsyncThunk(
  "profile/getDashBoard",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profileId}/dashboard`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get LeaderBoard

export const getLeaderBoard = createAsyncThunk(
  "profile/getLeaderBoard",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profileId}/leaderboard`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get streak
export const getStreak = createAsyncThunk(
  "profile/getStreak",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profileId}/streak`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get currnt fuel
export const getCurrentFuelData = createAsyncThunk(
  "profile/getCurrentFuel",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profileId}/current-fuel`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get profileFuel history
export const getFuelHistory = createAsyncThunk(
  "profile/getFuelHistory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profileId}/fuel-history`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//basic-settings

export const getBasicSettings = createAsyncThunk(
  "profile/getBasicSettings",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("user/basic-settings");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//claim
export const profileClaim = createAsyncThunk(
  "profile/profileClaim",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `user/profiles/${params.profile_Id}/claim-fuel/${params.slug}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//add to cart
export const addToCart = createAsyncThunk(
  "profile/addToCart",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `user/profiles/${params.profile_Id}/add-to-cart/${params.course_Id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//vvv
export const verifyCheckOutDetails = createAsyncThunk(
  "profile/verifyCheckOutDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("billing_address[name]", params.billing_address.name);
      formData.append("billing_address[parent]", params.billing_address.parent);
      formData.append("billing_address[email]", params.billing_address.email);
      formData.append(
        "billing_address[house_no]",
        params.billing_address.house_no ? params.billing_address.house_no : ""
      );
      formData.append(
        "billing_address[house_name]",
        params.billing_address.house_name
      );
      formData.append(
        "billing_address[street_name]",
        params.billing_address.street_name
      );
      formData.append(
        "billing_address[landmark]",
        params.billing_address.landmark
      );
      formData.append(
        "billing_address[post_office]",
        params.billing_address.post_office
      );
      formData.append("billing_address[zip]", params.billing_address.zip);

      formData.append("billing_address[city]", params.billing_address.city);
      formData.append(
        "billing_address[district_id]",
        params.billing_address.district_id
      );

      formData.append(
        "billing_address[state_id]",
        params.billing_address.state_id
      );
      formData.append(
        "billing_address[country_id]",
        params.billing_address.country_id
      );
      formData.append(
        "billing_address[is_for_shipping]",
        params.billing_address.is_for_shipping
      );
      if (params.billing_address?.is_for_shipping === 0) {
        formData.append("shipping_address[name]", params.shipping_address.name);
        formData.append(
          "shipping_address[email]",
          params.shipping_address.email
        );
        formData.append(
          "shipping_address[house_no]",
          params.shipping_address.house_no
        );
        formData.append(
          "shipping_address[house_name]",
          params.shipping_address.house_name
        );
        formData.append(
          "shipping_address[street_name]",
          params.shipping_address.street_name
        );
        formData.append(
          "shipping_address[landmark]",
          params.shipping_address.landmark
        );
        formData.append(
          "shipping_address[post_office]",
          params.shipping_address.post_office
        );
        formData.append("shipping_address[zip]", params.shipping_address.zip);

        formData.append("shipping_address[city]", params.shipping_address.city);
        formData.append(
          "shipping_address[district_id]",
          params.shipping_address.district_id
        );

        formData.append(
          "shipping_address[state_id]",
          params.shipping_address.state_id
        );
        formData.append(
          "shipping_address[country_id]",
          params.shipping_address.country_id
        );
      }

      const response = await api.post(
        `user/profiles/${params.profile_id}/checkout/${params.course_id}`,
        formData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const badgesEarned = createAsyncThunk(
  "profile/badgesEarned",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/earned-badges-types`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyPurchaseCompletion = createAsyncThunk(
  "profile/verifyPurchaseCompletion",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      let razorpayRes = params.razorpayRes;

      formData.append("status", params.status);
      formData.append("json_response", JSON.stringify(razorpayRes));
      formData.append("purchase_id", params.orderData.purchase_id);

      const response = await api.post(
        `user/profiles/${params.profile_Id}/complete-purchase`,
        formData
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const profileFlashCards = createAsyncThunk(
  "profile/profileFlashCards",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/flashcard-list?search=${params.searchText}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkoutDetails = createAsyncThunk(
  "profile/checkoutDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/checkout/${params.course_Id}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const videoPlaylist = createAsyncThunk(
  "profile/videoPlaylist",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/video-playlist?search=${params.searchText}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "profile/getNotifications",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/notifications`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPurchaseHistory = createAsyncThunk(
  "profile/getPurchaseHistory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/purchase-history`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPaceLanguage = createAsyncThunk(
  "profile/getPaceLanguage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user/pace-language-list`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLeaderBoardClubType = createAsyncThunk(
  "profile/getLeaderBoardClubType",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user/club-type-list`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStoreItems = createAsyncThunk(
  "profile/getStoreItems",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/profiles/${params.profile_Id}/store-items`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const buyNowItems = createAsyncThunk(
  "profile/buyNowItems",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("profile_id", params.profile_Id);
      formData.append("item_id", params.item_id);
      const response = await api.post(`user/profiles/buy-item`, formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUserProfileData: (state, action) => {
      const index = current(state.allProfiles).findIndex(
        (item) => item.id === action.payload.id
      );
      state.allProfiles[index] = {
        ...state.allProfiles[index],
        ...action.payload,
      };
    },

    updateUserSocialData: (state, action) => {
      const index = current(state.allProfiles).findIndex(
        (item) => item.id === action.payload.profile_Id
      );
      state.allProfiles[index] = {
        ...state.allProfiles[index],
        social_media_accounts: action.payload.social,
      };
    },

    updateProfileClaimState: (state, action) => {
      const index = current(state.allProfiles).findIndex(
        (item) => item.id === action.payload.profile_Id
      );
      state.allProfiles[index] = {
        ...state.allProfiles[index],
        profile_claim_fuel: true,
      };
    },

    removeProfilePicture: (state, action) => {
      const index = current(state.allProfiles).findIndex(
        (item) => item.id === action.payload.id
      );
      state.allProfiles[index] = {
        ...state.allProfiles[index],
        ...action.payload,
      };
    },

    updateStoreData: (state, action) => {
      state.storeItems = {
        ...state.storeItems,
        spend_points: action.payload.spend_points,
        profile_collection: action.payload.profile_collection,
      };
    },

    handleClickAccordion: (state, action) => {

      if (action.payload == "0") {
        state.accordionIndex = state?.accordionIndex == "0" ? "-1" : "0";
      } else if (action.payload == "1") {
        state.accordionIndex = state?.accordionIndex == "1" ? "-1" : "1";
      } else {
        state.accordionIndex = state?.accordionIndex == "2" ? "-1" : "2";
      }
    },
  },

  extraReducers: {
    //all profile
    [getAllProfiles.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAllProfiles.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allProfiles = action.payload?.data?.data;
    },

    [getAllProfiles.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.allProfiles = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    //profile
    [getProfileOfUser.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getProfileOfUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.userProfile = action.payload?.data?.data;
    },
    [getProfileOfUser.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.userProfile = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    //userupdate
    [updateUserProfile.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [updateUserProfile.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [updateUserProfile.rejected]: (state, action) => {
      state.status = "failed";
    },

    [updateProfilePhoto.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [updateProfilePhoto.fulfilled]: (state, action) => {
      state.status = "succeeded";

      const index = current(state.allProfiles).findIndex(
        (item) => item.id === action.payload.data.data.id
      );
      state.allProfiles[index] = {
        ...state.allProfiles[index],
        ...action.payload.data.data,
      };
    },
    [updateProfilePhoto.rejected]: (state, action) => {
      state.status = "failed";
    },

    [updateUserEducation.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [updateUserEducation.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.EducationMessage = action.payload?.data;
    },
    [updateUserEducation.rejected]: (state, action) => {
      state.status = "failed";
      state.EducationMessage = action.payload;
      // state.message = action.payload?.message;
    },

    //social media

    [updateUserSocialMedia.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [updateUserSocialMedia.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.socaialMediaMessage = action.payload?.data;
    },
    [updateUserSocialMedia.rejected]: (state, action) => {
      state.status = "failed";
      state.socaialMediaMessage = action.payload;
      // state.message = action.payload?.message;
    },

    //dashboard
    [getDashBoard.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getDashBoard.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.dashboardData = action.payload?.data?.data;
    },
    [getDashBoard.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.dashboardData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    //streak
    [getStreak.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getStreak.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.streak = action.payload?.data?.data;
    },
    [getStreak.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.streak = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    // getLeaderBoard'
    [getLeaderBoard.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getLeaderBoard.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.leaderBoard = action.payload?.data?.data;
    },
    [getLeaderBoard.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.leaderBoard = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    //curr fuel
    [getCurrentFuelData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCurrentFuelData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.currentFuel = action.payload?.data?.data;
    },
    [getCurrentFuelData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.currentFuel = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    // fuel history
    [getFuelHistory.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFuelHistory.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.fuelHistory = action.payload?.data?.data;
    },
    [getFuelHistory.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.fuelHistory = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    //basic settings

    [getBasicSettings.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBasicSettings.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.basicSettings = action.payload?.data?.data;
    },
    [getBasicSettings.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.basicSettings = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    //notifications

    [getNotifications.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.notificationsRead = false;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.notifications = action.payload?.data?.data;
      state.notificationsRead = false;
    },
    [getNotifications.rejected]: (state, action) => {
      state.status = "failed";
      state.notificationsRead = false;

      if (action.payload?.data?.length !== 0) {
        state.notifications = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    [markAsReadNotifications.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.notificationsRead = false;
    },
    [markAsReadNotifications.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.notificationsRead = true;
    },
    [markAsReadNotifications.rejected]: (state, action) => {
      state.status = "failed";
      state.notificationsRead = false;
    },

    [getStoreItems.pending]: (state, action) => {
      state.status = "loading";
    },
    [getStoreItems.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.storeItems = action.payload;
    },
    [getStoreItems.rejected]: (state, action) => {
      state.status = "failed";
    },

    [profileFlashCards.pending]: (state, action) => {
      state.status = "loading";
    },
    [profileFlashCards.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [profileFlashCards.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const {
  updateUserProfileData,
  removeProfilePicture,
  updateUserSocialData,
  updateProfileClaimState,
  updateStoreData,
  handleClickAccordion,
} = profileSlice.actions;

export default profileSlice.reducer;
