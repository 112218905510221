import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
      var body = document.body;
      // body.classList.remove("is-inview");
      // body.classList.add("is-inview");

      if (history.location.pathname.includes("/leaderboard")) {
        body.classList.add("leaderboard-page");
      } else {
        body.classList.remove("leaderboard-page");
      }

      if (
        history.location.pathname.includes("/questions") ||
        history.location.pathname.includes("/flashcard") ||
        history.location.pathname.includes("/cuevideo")
      ) {
        body.classList.add("questions-page");
      } else {
        body.classList.remove("questions-page");
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
