import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
};

export const logFlashCard = createAsyncThunk(
  "flashcard/logFlashCard",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        "learner/audit/flashcard",
        {
          loggable_id: params.loggable_id,
          status: params.status,
          cue_element_order_id: params.cue_element_order_id,
        },
        {
          headers: { "X-Accept-Learner": params?.leanderId },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const flashcardSlice = createSlice({
  name: "flashcard",
  initialState,
  reducers: {},
  extraReducers: {
    [logFlashCard.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [logFlashCard.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.logData = action.payload?.data;
    },
    [logFlashCard.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.logData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default flashcardSlice.reducer;
